import { Input, Modal } from 'antd';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

interface Props {
  loading: boolean;
  onCancel: () => void;
  onConfirm: (fileTitle: string) => void;
}

const ExportModal = ({ loading, onCancel, onConfirm }: Props) => {
  const [fileTitle, setFileTitle] = useState<string>('');

  useEffect(() => {
    const date = format(new Date(), 'dd_MM_yyyy');

    setFileTitle(`export_gotting_${date}.pdf`);
  }, []);

  return (
    <Modal
      width={900}
      centered
      title="Exporter les données"
      okText="Exporter"
      cancelText="Annuler"
      open={true}
      okButtonProps={{ loading }}
      onOk={() => onConfirm(fileTitle)}
      onCancel={onCancel}
    >
      <div>
        <Input
          value={fileTitle}
          onChange={(e) => setFileTitle(e.target.value)}
        />
      </div>
    </Modal>
  );
};

export default ExportModal;
