import useSWR from 'swr';
import { Table, Spin, Input, Button, Flex, Popconfirm } from 'antd';
import { useState } from 'react';
import {
  handleCreateLocation,
  handleDeleteLocation,
} from '../api/locations.api';
import { DeleteOutlined } from '@ant-design/icons';
import { Location } from '../constants';

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '30%',
  },
  {
    title: 'Titre',
    dataIndex: 'title',
    key: 'title',
    width: '70%',
  },
  {
    title: 'Action',
    key: 'action',
    width: '5%',
    render: (_: void, record: Location) => (
      <Popconfirm
        title="Êtes-vous sûr?"
        onConfirm={(e) => {
          e?.stopPropagation();
          handleDeleteLocation(record.id);
        }}
        onCancel={(e) => e?.stopPropagation()}
        okText="Yes"
        cancelText="No"
      >
        <Button danger onClick={(e) => e.stopPropagation()}>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    ),
  },
];

const LocationList = () => {
  const { data, error, isLoading } = useSWR<any[]>('/locations');
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <div>Une erreur est survenue</div>;
  }

  const handleAdd = async () => {
    setIsAdding(true);
    await handleCreateLocation({
      title: value,
    });
    setIsAdding(false);
    setValue('');
  };

  return (
    <div>
      <h1 style={{ marginRight: '24px' }}>{`Lieux (${data?.length})`}</h1>

      <Flex style={{ width: '300px', marginBottom: '24px' }}>
        <Input
          disabled={isAdding || isLoading}
          placeholder="Ajouter un nouveau lieu"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <Button
          disabled={isAdding || isLoading || !value}
          loading={isAdding}
          style={{
            marginLeft: '12px',
            lineHeight: '21px',
          }}
          type="primary"
          onClick={handleAdd}
        >
          Ajouter
        </Button>
      </Flex>

      <Table
        style={{ overflow: 'auto', height: '800px' }}
        dataSource={data}
        columns={columns}
        pagination={false}
        rowKey={(row: any) => row.id}
      />
    </div>
  );
};

export default LocationList;
