import { Button } from 'antd';
import { useAtom } from 'jotai';
import { useAuth } from '../api/auth.api';
import { isLoggedInAtom } from '../atoms';

const LogoutComponent = () => {
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  const { logout } = useAuth();

  const handlePressed = () => {
    logout();
    window.location.href = '/login';
  };

  if (isLoggedIn) {
    return (
      <Button style={{ color: 'white' }} type="text" onClick={handlePressed}>
        Se déconnecter
      </Button>
    );
  }

  return null;
};

export default LogoutComponent;
