import { Artwork, ERROR_MESSAGE_GENERIC } from '../constants';
import { mutate } from 'swr';
import { message } from 'antd';
import { apiClient } from './api';

const createArtwork = async (artwork: Partial<Artwork>) => {
  const response = await apiClient.post('artworks', artwork);
  return response.data;
};

const updateArtwork = async (artwork: Partial<Artwork>) => {
  const response = await apiClient.patch(`artworks/${artwork.id}`, artwork);
  return response.data;
};

const deleteArtwork = async (artwork: Partial<Artwork>) => {
  const response = await apiClient.delete(`artworks/${artwork.id}`);
  return response.data;
};

export const handleCreateArtwork = async (artwork: Partial<Artwork>) => {
  try {
    const payload = { ...artwork } as any;

    if (payload.currentLocation && payload.currentLocation.id) {
      payload.currentLocation = payload.currentLocation.id;
    }
    if (payload.project && payload.project.id) {
      payload.project = payload.project.id;
    }

    if (payload.madeDate) {
      payload.madeDate = new Date(payload.madeDate).toISOString();
    }

    await createArtwork(payload);
    mutate('/inventory');
  } catch (error) {
    message.error(ERROR_MESSAGE_GENERIC);
    console.error(error);
    throw error;
  }
};

export const handleUpdateArtwork = async (artwork: Partial<Artwork>) => {
  try {
    const payload = { ...artwork } as any;

    if (payload.currentLocation && payload.currentLocation.id) {
      payload.currentLocation = payload.currentLocation.id;
    }
    if (payload.project && payload.project.id) {
      payload.project = payload.project.id;
    }

    if (payload.madeDate) {
      payload.madeDate = new Date(payload.madeDate).toISOString();
    }

    await updateArtwork(payload);
    mutate('/inventory');
  } catch (error) {
    message.error(ERROR_MESSAGE_GENERIC);
    console.error(error);
    throw error;
  }
};

export const handleDeleteArtwork = async (artwork: Partial<Artwork>) => {
  try {
    await deleteArtwork(artwork);
    mutate('/inventory');
  } catch (error) {
    message.error(ERROR_MESSAGE_GENERIC);
    console.error(error);
    throw error;
  }
};
