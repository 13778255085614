import { mutate } from 'swr';
import { Location } from '../constants';
import { apiClient } from './api';

const createLocation = async (location: Partial<Location>) => {
  const response = await apiClient.post('locations', location);
  return response.data;
};

const deleteLocation = async (locationId: string) => {
  const response = await apiClient.delete(`locations/${locationId}`);
  return response.data;
};

export const handleCreateLocation = async (location: Partial<Location>) => {
  try {
    await createLocation(location);
    mutate('/locations');
  } catch (error) {
    console.log(error);
  }
};

export const handleDeleteLocation = async (locationId: string) => {
  try {
    await deleteLocation(locationId);
    mutate('/locations');
  } catch (error) {
    throw error;
  }
};
