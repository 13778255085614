import { useAtom } from 'jotai';
import { Navigate } from 'react-router-dom';
import { isLoggedInAtom } from '../atoms';

interface ProtectedRouteProps {
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const [isLoggedIn] = useAtom(isLoggedInAtom);

  if (!isLoggedIn) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

export default ProtectedRoute;
