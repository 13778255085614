import { Input, Tag } from 'antd';
import { useState } from 'react';

const KeywordsInput = ({
  keywords,
  setKeywords,
}: {
  keywords: string[];
  setKeywords: (keywords: string[]) => void;
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue) {
      const newKeywords = inputValue
        .split(',')
        .map((keyword) => keyword.trim())
        .filter((keyword) => keyword);
      setKeywords(Array.from(new Set([...keywords, ...newKeywords])));
      setInputValue('');
    }
  };

  const handleRemoveKeyword = (removedKeyword: string) => {
    setKeywords(keywords.filter((keyword) => keyword !== removedKeyword));
  };

  return (
    <div>
      <div style={{ marginBottom: 8 }}>
        {keywords.map((keyword) => (
          <Tag
            key={keyword}
            closable
            onClose={() => handleRemoveKeyword(keyword)}
          >
            {keyword}
          </Tag>
        ))}
      </div>
      <Input
        placeholder="Entrez des mots-clés"
        value={inputValue}
        onChange={handleInputChange}
        onPressEnter={handleInputConfirm}
        onBlur={handleInputConfirm}
      />
    </div>
  );
};

export default KeywordsInput;
