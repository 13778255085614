import React from 'react';
import { Layout } from 'antd';
import { SWRConfig } from 'swr';
import HomePage from './pages/HomePage';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import { apiClient } from './api/api';
import { useAtom } from 'jotai';
import { isLoggedInAtom } from './atoms';
import ProtectedRoute from './components/ProtectedRoute';
import LogoutComponent from './components/LogoutComponent';
import './App.css';

const { Header, Footer } = Layout;

const App: React.FC = () => {
  const [isLoggedIn] = useAtom(isLoggedInAtom);

  return (
    <SWRConfig
      value={{
        fetcher: (url: string) => apiClient.get(url).then((res) => res.data),
      }}
    >
      <Layout>
        {isLoggedIn && (
          <Header style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginLeft: 'auto' }}>
              <LogoutComponent />
            </div>
          </Header>
        )}

        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <HomePage />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Router>

        <Footer style={{ textAlign: 'center', fontSize: '10px' }}>
          Inventory Manager ©{new Date().getFullYear()} Created by Oscar
          Gotting
        </Footer>
      </Layout>
    </SWRConfig>
  );
};

export default App;
