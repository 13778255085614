import { useEffect, useState } from 'react';
import {
  Modal,
  Spin,
  Flex,
  Form,
  Input,
  DatePicker,
  Radio,
  Select,
  InputNumber,
} from 'antd';
import useSWR from 'swr';
import {
  Artwork,
  Availability,
  formatSupport,
  formatTechnique,
  Support,
  Technique,
} from '../constants';
import dayjs from 'dayjs';
import LocationDropdown from '../components/LocationDropdown';
import ProjectDropdown from '../components/ProjectDropdown';
import KeywordsInput from '../components/KeywordsInput';
import { handleCreateArtwork, handleUpdateArtwork } from '../api/artworks.api';
import ImageUploader from '../components/ImageUploader';
import SkeletonImage from 'antd/es/skeleton/Image';
import SkeletonInput from 'antd/es/skeleton/Input';

interface Props {
  itemId?: number;
  onClose: () => void;
}

const emptyItem = {
  project: undefined,
  imageId: undefined,
  title: undefined,
  reference: undefined,
  madeDate: new Date(),
  format: undefined,
  support: undefined,
  technique: undefined,
  keywords: [],
  availability: Availability.AVAILABLE,
  price: 0,
  priceWithoutTax: 0,
  soldDate: undefined,
  soldLocation: undefined,
  buyer: undefined,
  notes: undefined,
  currentLocation: undefined,
};

const AddOrEditItemModal = ({ itemId, onClose }: Props) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [item, setItem] = useState<Partial<Artwork>>(emptyItem);

  let { data, error, isLoading } = useSWR(itemId ? `/artwork/${itemId}` : null);

  useEffect(() => {
    if (data) {
      setItem(data);
    }
  }, [data]);

  const canSubmit = () => {
    return item.title;
  };

  const changeValueByEvent = (event: any, field: string) => {
    const value = event.target.value || undefined;
    form.setFieldsValue({ [field]: value });
    setItem({
      ...item,
      [field]: value,
    });
  };

  const changeValueByDate = (date: any, field: string) => {
    form.setFieldsValue({ [field]: date });

    setItem({
      ...item,
      [field]: date,
    });
  };

  const changeValueByString = (value: string | number, field: string) => {
    form.setFieldsValue({ [field]: value });

    setItem({
      ...item,
      [field]: value,
    });
  };

  const handleSave = async () => {
    try {
      setIsSubmitting(true);
      // update existing artwork
      if (itemId) {
        await handleUpdateArtwork(item);
      }
      // create new artwork
      else {
        await handleCreateArtwork(item);
      }
      onClose();
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };

  if (error) {
    return <div>Une erreur est survenue</div>;
  }

  return (
    <Modal
      maskClosable={false}
      width={900}
      centered
      title="Ajouter une oeuvre"
      okText="Sauvegarder"
      cancelText="Annuler"
      open={true}
      okButtonProps={{ disabled: !canSubmit(), loading: isSubmitting }}
      onOk={handleSave}
      onCancel={onClose}
    >
      {isLoading && (
        <div
          style={{
            minHeight: 1000,
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Spin />
        </div>
      )}

      {!isLoading && (
        <div style={{ paddingTop: '24px', paddingBottom: '24px' }}>
          <Form form={form} layout="vertical">
            <Flex vertical>
              <Form.Item
                label="Titre de l'oeuvre"
                style={{ fontWeight: 'bold' }}
              >
                <Input
                  value={item.title || ''}
                  placeholder="Titre (obligatoire)"
                  onChange={(e) => changeValueByEvent(e, 'title')}
                />
              </Form.Item>

              <Flex gap={24} style={{ width: '100%' }}>
                <Flex vertical style={{ width: '50%' }}>
                  <div style={{ marginBottom: '12px' }}>
                    <ImageUploader
                      imageId={item.imageId}
                      onUploaded={(imageId: number) =>
                        changeValueByString(imageId, 'imageId')
                      }
                    />
                  </div>

                  <Form.Item label="Projet" style={{ fontWeight: 'bold' }}>
                    <ProjectDropdown
                      currentProjectId={item.project?.id}
                      onChangeProject={(newProject) => {
                        setItem({
                          ...item,
                          project: newProject,
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Disponibilité"
                    style={{ fontWeight: 'bold' }}
                  >
                    <Radio.Group
                      value={item.availability ?? 0}
                      onChange={(e) => changeValueByEvent(e, 'availability')}
                    >
                      <Radio value={Availability.AVAILABLE}>Disponible</Radio>
                      <Radio value={Availability.SOLD}>Vendue</Radio>
                    </Radio.Group>
                  </Form.Item>

                  <Form.Item
                    label="Localisation Actuelle"
                    style={{ fontWeight: 'bold' }}
                  >
                    <LocationDropdown
                      currentLocationId={item.currentLocation?.id}
                      onChangeLocation={(newLocation) => {
                        setItem({
                          ...item,
                          currentLocation: newLocation,
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item label="Mots-clés" style={{ fontWeight: 'bold' }}>
                    <KeywordsInput
                      keywords={item.keywords || []}
                      setKeywords={(keywords) => {
                        setItem({
                          ...item,
                          keywords: keywords,
                        });
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Notes sur la vente"
                    style={{ fontWeight: 'bold' }}
                  >
                    <Input.TextArea
                      rows={10}
                      value={item.notes}
                      onChange={(e) => changeValueByEvent(e, 'notes')}
                    />
                  </Form.Item>
                </Flex>

                <Flex vertical style={{ width: '50%' }}>
                  <Form.Item label="Reference #" style={{ fontWeight: 'bold' }}>
                    <Input
                      value={item.reference || ''}
                      placeholder="XXXXX"
                      onChange={(e) => changeValueByEvent(e, 'reference')}
                    />
                  </Form.Item>

                  <Form.Item
                    label="Date de création"
                    style={{ fontWeight: 'bold' }}
                  >
                    <DatePicker
                      value={
                        item.madeDate ? dayjs(new Date(item.madeDate)) : null
                      }
                      onChange={(date) => changeValueByDate(date, 'madeDate')}
                    />
                  </Form.Item>

                  <Form.Item label="Format" style={{ fontWeight: 'bold' }}>
                    <Input
                      value={item.format || ''}
                      placeholder="75x75"
                      onChange={(e) => changeValueByEvent(e, 'format')}
                    />
                  </Form.Item>

                  <Form.Item label="Support" style={{ fontWeight: 'bold' }}>
                    <Select
                      value={item.support}
                      onChange={(value) =>
                        changeValueByString(value, 'support')
                      }
                    >
                      {Object.values(Support).map((s) => (
                        <Select.Option key={s}>
                          {formatSupport(s)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Technique" style={{ fontWeight: 'bold' }}>
                    <Select
                      value={item.technique}
                      onChange={(e) => changeValueByString(e, 'technique')}
                    >
                      {Object.values(Technique).map((t) => (
                        <Select.Option key={t}>
                          {formatTechnique(t)}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>

                  <Form.Item label="Prix" style={{ fontWeight: 'bold' }}>
                    <InputNumber
                      style={{ width: '100%' }}
                      value={item.price ? item.price : 0}
                      formatter={(value) =>
                        value ? Number(value / 100).toString() : '0'
                      }
                      min={0}
                      placeholder="9500.00"
                      onChange={(value) => {
                        const centValue = Math.round(Number(value) * 100);
                        changeValueByString(centValue, 'price');
                      }}
                    />
                  </Form.Item>

                  <div
                    style={{
                      backgroundColor: '#A6AEBF',
                      padding: '24px',
                      borderRadius: '8px',
                    }}
                  >
                    <Form.Item
                      label="Prix Vendu"
                      style={{ fontWeight: 'bold' }}
                    >
                      <InputNumber
                        style={{ width: '100%' }}
                        value={item.priceWithoutTax ? item.priceWithoutTax : 0}
                        formatter={(value) =>
                          value ? Number(value / 100).toString() : '0'
                        }
                        min={0}
                        placeholder="9500.00"
                        onChange={(value) => {
                          const centValue = Math.round(Number(value) * 100);
                          changeValueByString(centValue, 'priceWithoutTax');
                        }}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Date de la vente"
                      style={{ fontWeight: 'bold' }}
                    >
                      <DatePicker
                        value={
                          item.soldDate ? dayjs(new Date(item.soldDate)) : null
                        }
                        onChange={(e) => changeValueByDate(e, 'soldDate')}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Lieu de la vente"
                      style={{ fontWeight: 'bold' }}
                    >
                      <Input
                        value={item.soldLocation || ''}
                        placeholder="Paris"
                        onChange={(e) => changeValueByEvent(e, 'soldLocation')}
                      />
                    </Form.Item>

                    <Form.Item label="Acheteur" style={{ fontWeight: 'bold' }}>
                      <Input
                        value={item.buyer || ''}
                        placeholder="Jean Dupuis"
                        onChange={(e) => changeValueByEvent(e, 'buyer')}
                      />
                    </Form.Item>
                  </div>
                </Flex>
              </Flex>
            </Flex>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default AddOrEditItemModal;
