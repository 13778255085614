import { Content } from 'antd/es/layout/layout';
import TabsComponent from '../TabsComponent';
import { theme } from 'antd';

const HomePage = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Content style={{ padding: '0 196px', height: '100%' }}>
      <div
        style={{
          marginTop: '64px',
          background: colorBgContainer,
          minHeight: 280,
          padding: 24,
          borderRadius: borderRadiusLG,
        }}
      >
        <TabsComponent />
      </div>
    </Content>
  );
};

export default HomePage;
