export interface Artwork {
  id: number;
  project?: Project;
  imageId?: number;
  title: string;
  reference?: string;
  madeDate?: string | Date;
  format?: string;
  support?: string;
  technique?: string;
  keywords?: string[];
  availability: Availability;
  price?: number;
  priceWithoutTax?: number;
  soldDate?: string;
  soldLocation?: string;
  buyer?: string;
  notes?: string;
  currentLocation?: Location;
}

export interface Project {
  id: string;
  title: string;
}

export interface Location {
  id: string;
  title: string;
}

export enum Availability {
  AVAILABLE = 'AVAILABLE',
  SOLD = 'SOLD',
}

export const DEFAULT_PRICE_RANGE_UPPER_BOUND = 10000;

export enum SearchType {
  TITLE,
  REFERENCE,
  KEYWORD,
  PRICE_RANGE,
  AVAILABILITY,
  LOCATION,
  PROJECT
}

export enum Support {
  CANVAS = 'CANVAS',
  PAPER = 'PAPER',
  SCRATCH_CARD = 'SCRATCH_CARD',
  LINO = 'LINO',
}

export enum Technique {
  ACRYLIC = 'ACRYLIC',
  INK = 'INK',
  PENCIL = 'PENCIL',
  LINO = 'LINO',
  ENGRAVED = 'ENGRAVED',
}

export function formatSupport(support: Support | string) {
  if (!support) {
    return 'N/A';
  }
  switch (support as Support) {
    case Support.CANVAS:
      return 'Toile';
    case Support.PAPER:
      return 'Papier';
    case Support.SCRATCH_CARD:
      return 'Carte à gratter';
    case Support.LINO:
      return 'Lino';
    default:
      return 'Autre';
  }
}

export function formatTechnique(technique?: Technique | string) {
  if (!technique) {
    return 'N/A';
  }
  switch (technique as Technique) {
    case Technique.ACRYLIC:
      return 'Acrylique';
    case Technique.INK:
      return 'Encre';
    case Technique.PENCIL:
      return 'Crayon';
    case Technique.LINO:
      return 'Lino';
    case Technique.ENGRAVED:
      return 'Gravure';
    default:
      return 'Autre';
  }
}

export const ERROR_MESSAGE_GENERIC = 'Une erreur est survenue';
export const IMAGE_URL = `${process.env.REACT_APP_BASE_URL}/images`;

export const getServerImageUrl = (imageId?: number): string => {
  if (!imageId) return 'https://via.placeholder.com/72x72.png?text=No+Image';
  return `${IMAGE_URL}/${imageId}`;
};
