import { Spin, Select } from 'antd';
import useSWR from 'swr';
import { Location } from '../constants';

const LocationDropdown = ({
  currentLocationId,
  onChangeLocation,
}: {
  currentLocationId?: string;
  onChangeLocation: (loc?: Location) => void;
}) => {
  const { data, error, isLoading } = useSWR<Location[]>('/locations');

  if (error) {
    return <div>Une erreur est survenue</div>;
  }
  if (isLoading) {
    return <Spin />;
  }

  if (!data) {
    return <div>No data</div>;
  }

  return (
    <Select
      style={{ width: '100%' }}
      value={currentLocationId}
      onChange={(locationId: string) =>
        onChangeLocation(
          data.find((location: Location) => location.id === locationId),
        )
      }
    >
      {data?.map((location) => {
        return (
          <Select.Option key={location.id}>{location.title}</Select.Option>
        );
      })}
    </Select>
  );
};

export default LocationDropdown;
