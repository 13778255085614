import { Upload, message, Image as AntdImage, Spin, Flex } from 'antd';
import { apiClient } from '../api/api';
import { UploadChangeParam, UploadFile } from 'antd/es/upload';
import { useState } from 'react';
import { IMAGE_URL } from '../constants';

const NO_IMAGE_FALLBACK_URL =
  'https://via.placeholder.com/200x200.png?text=No+Image';

interface Props {
  imageId?: number;
  onUploaded: (imageId: number) => void;
}

const ImageUploader = ({ imageId, onUploaded }: Props) => {
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const handleCustomRequest = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      setIsUploading(true);
      const response = await apiClient.post('images', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      onSuccess(response.data);
      onUploaded(response.data.imageId);

      message.success('Image sauvée avec succès');
    } catch (error) {
      onError(error);
      message.error("Erreur lors de l'upload");
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Upload
      customRequest={handleCustomRequest}
      showUploadList={false}
      accept="image/*"
    >
      {renderImage(imageId, isUploading)}
    </Upload>
  );
};

const LoadingImageContainer = () => {
  return (
    <Flex
      style={{
        backgroundColor: 'lightgray',
        justifyContent: 'center',
        alignItems: 'center',
        width: '200px',
        height: '200px',
      }}
    >
      <Spin size="default" />
    </Flex>
  );
};

const renderImage = (imageId: number | undefined, isUploading: boolean) => {
  if (isUploading) {
    return <LoadingImageContainer />;
  }
  if (!imageId) {
    return (
      <Flex
        style={{
          backgroundColor: 'gray',
          width: '200px',
          height: '200px',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          color: 'white',
        }}
      >
        Aucune Image
      </Flex>
    );
  }

  return (
    <AntdImage
      fallback={NO_IMAGE_FALLBACK_URL}
      preview={false}
      width={200}
      src={`${IMAGE_URL}/${imageId}`}
      placeholder={<LoadingImageContainer />}
    />
  );
};

export default ImageUploader;
