import { Artwork, SearchType } from '../constants';

export const filterData = (
  data: Artwork[],
  searchType: SearchType,
  searchValue: string,
  searchValueSecondary?: string,
  selectedKeywords?: string[],
) => {
  if (!data.length) {
    return [];
  }

  if (!searchValue.length && !selectedKeywords?.length) {
    return data;
  }

  if (searchType === SearchType.TITLE) {
    return data.filter((item) =>
      item.title.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }

  if (searchType === SearchType.REFERENCE) {
    return data.filter((item) =>
      item.reference?.toLowerCase().includes(searchValue.toLowerCase()),
    );
  }

  if (searchType === SearchType.PRICE_RANGE) {
    return data.filter(
      (item) =>
        item.price &&
        item.price / 100 >= Number(searchValue) &&
        item.price / 100 < Number(searchValueSecondary),
    );
  }

  if (searchType === SearchType.LOCATION) {
    return data.filter((item) => item.currentLocation?.id == searchValue);
  }
  
  if (searchType === SearchType.PROJECT) {
    return data.filter((item) => item.project?.id == searchValue);
  }

  if (searchType === SearchType.AVAILABILITY) {
    return data.filter((item) => item.availability.toString() === searchValue);
  }

  if (searchType === SearchType.KEYWORD) {
    if (!selectedKeywords) {
      return data;
    }
    return data.filter((item: Artwork) =>
      item.keywords?.some((keyword) => selectedKeywords.includes(keyword)),
    );
  }

  return data;
};
