import { Spin, Select } from 'antd';
import useSWR from 'swr';
import { Project } from '../constants';

interface Props {
  currentProjectId?: string;
  onChangeProject: (project?: Project) => void;
}

const ProjectDropdown = ({ currentProjectId, onChangeProject }: Props) => {
  const { data, error, isLoading } = useSWR<Project[]>('/projects');

  if (error) {
    return <div>Une erreur est survenue</div>;
  }
  if (isLoading) {
    return <Spin />;
  }

  return (
    <Select
      style={{ width: '100%' }}
      value={currentProjectId}
      onChange={(projecId: string) =>
        onChangeProject(data?.find((p: Project) => p.id === projecId))
      }
    >
      {data?.map((project) => {
        return <Select.Option key={project.id}>{project.title}</Select.Option>;
      })}
    </Select>
  );
};

export default ProjectDropdown;
