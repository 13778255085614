import { Tag } from 'antd';
import { useEffect, useState } from 'react';

interface Keyword {
  name: string;
  selected: boolean;
}

interface Props {
  keywordList: string[];
  onSelectedKeywords: (keywords: string[]) => void;
}

const KeywordSelector = ({ keywordList, onSelectedKeywords }: Props) => {
  const [keywords, setKeywords] = useState<Keyword[]>([]);

  useEffect(() => {
    if (keywordList.length > 0) {
      setKeywords(
        keywordList.map((keyword) => ({
          name: keyword,
          selected: false,
        })),
      );
    }
  }, [keywordList]);

  const handleClick = (name: string) => {
    const newKeywords = keywords.map((keyword) =>
      keyword.name === name
        ? { ...keyword, selected: !keyword.selected }
        : keyword,
    );
    setKeywords(newKeywords);
    onSelectedKeywords(
      newKeywords.filter((k) => k.selected).map((k) => k.name),
    );
  };

  return (
    <div>
      {keywords.map((keyword) => {
        return (
          <Tag
            onClick={() => handleClick(keyword.name)}
            color={keyword.selected ? 'green' : ''}
            key={keyword.name}
          >
            {keyword.name}
          </Tag>
        );
      })}
    </div>
  );
};

export default KeywordSelector;
