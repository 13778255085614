import useSWR from 'swr';
import {
  Table,
  Spin,
  Image,
  Flex,
  Button,
  Input,
  Select,
  Slider,
  Radio,
  Checkbox,
  Dropdown,
  Tag,
  Popconfirm,
  Tooltip,
  message,
} from 'antd';
import {
  PlusOutlined,
  ExportOutlined,
  DashOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';
import AddOrEditItemModal from '../modals/AddOrEditItemModal';
import { Key, useEffect, useMemo, useState } from 'react';
import {
  Availability,
  DEFAULT_PRICE_RANGE_UPPER_BOUND,
  Artwork,
  SearchType,
  formatSupport,
  formatTechnique,
  Support,
  Technique,
  Project,
  Location,
  getServerImageUrl,
} from '../constants';
import { filterData } from '../utils/search';
import { exportData } from '../utils/export';
import ExportModal from '../modals/ExportModal';
import LocationDropdown from '../components/LocationDropdown';
import KeywordSelector from '../components/KeywordsSelector';
import { handleDeleteArtwork } from '../api/artworks.api';
import ProjectDropdown from '../components/ProjectDropdown';

const columns = [
  {
    title: 'Date',
    dataIndex: 'madeDate',
    key: 'madeDate',
    render: (val: string) => {
      if (!val) {
        return '';
      }
      return <div>{format(new Date(val), 'dd/MM/yyyy')}</div>;
    },
    sorter: (a: Artwork, b: Artwork) =>
      new Date(a.madeDate || 0).getTime() - new Date(b.madeDate || 0).getTime(),
    width: '5%',
  },
  {
    title: 'Ref#',
    dataIndex: 'reference',
    key: 'reference',
    width: '5%',
    render: (val?: string) => <div>{val || 'N/A'}</div>,
  },

  {
    title: 'Image',
    dataIndex: 'imageId',
    key: 'imageId',
    render: (imageId?: number) => (
      <div>
        <Image
          fallback="https://via.placeholder.com/72x72.png?text=No+Image"
          width={72}
          height={72}
          src={getServerImageUrl(imageId)}
          placeholder={
            <div
              style={{
                display: 'flex',
                width: '72px',
                height: '72px',
              }}
            >
              <Spin size="default" />
            </div>
          }
        />
      </div>
    ),
    width: '5%',
  },

  {
    title: 'Titre',
    dataIndex: 'title',
    key: 'title',
    width: '10%',
  },
  {
    title: 'Disponibilité',
    dataIndex: 'availability',
    key: 'availability',
    render: (val: Availability) => {
      const sold = val === Availability.SOLD;
      return (
        <div
          style={{
            width: '75px',
            backgroundColor: sold ? '#FF8A8A' : '#CCE0AC',
            borderRadius: '16px',
            textAlign: 'center',
            color: 'white',
            fontWeight: 500,
            fontSize: '12px',
            padding: '2px 0',
          }}
        >
          {sold ? 'Vendue' : 'Disponible'}
        </div>
      );
    },
    filters: Object.values(Availability).map((value) => ({
      text: value === Availability.AVAILABLE ? 'Disponible' : 'Vendue',
      value,
    })),
    onFilter: (value: boolean | Key, record: Artwork) =>
      record.availability == value,
    width: '5%',
  },
  {
    title: 'Prix',
    dataIndex: 'price',
    key: 'price',
    sorter: (a: Artwork, b: Artwork) => {
      let aPrice =
        (a.availability === Availability.AVAILABLE
          ? a.price
          : a.priceWithoutTax) || 0;
      let bPrice =
        (a.availability === Availability.AVAILABLE
          ? b.price
          : b.priceWithoutTax) || 0;
      return aPrice - bPrice;
    },
    render: (_val: number, record: Artwork) => {
      let price;
      if (record.availability === Availability.AVAILABLE) {
        price = (record.price || 0) / 100;
      } else {
        // wrongly named field, should be soldPrice...
        price = (record.priceWithoutTax || 0) / 100;
      }
      return (
        <Tooltip
          title={
            record.availability === Availability.AVAILABLE
              ? 'Prix listé'
              : 'Prix vendu'
          }
        >
          <div>{price}€</div>
        </Tooltip>
      );
    },
    width: '5%',
  },
  {
    title: 'Projet',
    dataIndex: 'project',
    key: 'project',
    width: '15%',
    render: (val?: Project) => <div>{val?.title || 'N/A'}</div>,
  },
  {
    title: 'Format',
    dataIndex: 'format',
    key: 'format',
    width: '5%',
    render: (val?: string) => <div>{val || 'N/A'}</div>,
  },
  {
    title: 'Support',
    dataIndex: 'support',
    key: 'support',
    width: '10%',
    render: (val: Support) => <div>{formatSupport(val)}</div>,
    filters: Object.values(Support).map((value) => ({
      text: formatSupport(value),
      value,
    })),
    onFilter: (value: boolean | Key, record: Artwork) =>
      record.support == value,
  },
  {
    title: 'Technique',
    dataIndex: 'technique',
    key: 'technique',
    width: '10%',
    render: (val: string) => <div>{formatTechnique(val)}</div>,
    filters: Object.values(Technique).map((value) => ({
      text: formatTechnique(value),
      value,
    })),
    onFilter: (value: boolean | Key, record: Artwork) =>
      record.technique == value,
  },
  {
    title: 'Localisation',
    dataIndex: 'currentLocation',
    key: 'currentLocation',
    width: '10%',
    render: (val?: Location) => <div>{val?.title || 'N/A'}</div>,
  },
  {
    title: 'Mots-Clés',
    dataIndex: 'keywords',
    key: 'keywords',
    width: '15%',
    render: (keywords: string[]) => (
      <div>
        {keywords.map((keyword) => (
          <Tag key={keyword}>{keyword}</Tag>
        ))}
      </div>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_: void, record: Artwork) => (
      <Popconfirm
        title="Êtes-vous sûr?"
        onConfirm={(e) => {
          e?.stopPropagation();
          handleDeleteArtwork(record);
        }}
        onCancel={(e) => e?.stopPropagation()}
        okText="Yes"
        cancelText="No"
      >
        <Button danger onClick={(e) => e.stopPropagation()}>
          <DeleteOutlined />
        </Button>
      </Popconfirm>
    ),
  },
];

const SearchModeSelector = ({
  searchType,
  onSearchTypeChanged,
}: {
  searchType: SearchType;
  onSearchTypeChanged: (type: SearchType) => void;
}) => {
  return (
    <Select
      style={{ width: '250px' }}
      placeholder="Critère de recherche"
      value={searchType}
      onChange={onSearchTypeChanged}
    >
      <Select.Option key="title" value={SearchType.TITLE}>
        Par Titre
      </Select.Option>
      <Select.Option key="reference" value={SearchType.REFERENCE}>
        Par Reference
      </Select.Option>
      <Select.Option key="keywords" value={SearchType.KEYWORD}>
        Par Mot-clé
      </Select.Option>
      <Select.Option key="price" value={SearchType.PRICE_RANGE}>
        Par Tranche de Prix
      </Select.Option>
      <Select.Option key="availability" value={SearchType.AVAILABILITY}>
        Par Disponibilité
      </Select.Option>
      <Select.Option key="location" value={SearchType.LOCATION}>
        Par Localisation
      </Select.Option>
      <Select.Option key="project" value={SearchType.PROJECT}>
        Par Projet
      </Select.Option>
    </Select>
  );
};

const InventoryList = () => {
  const { data, error, isLoading } = useSWR<Artwork[]>('/inventory');
  const [currentDataSource, setCurrentDataSource] = useState<Artwork[]>([]);
  const [search, setSearch] = useState<string>('');
  const [searchSecondary, setSearchSecondary] = useState<string>('');
  const [selectedKeywords, setSelectedKeywords] = useState<string[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState<boolean>(false);
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [searchType, setSearchType] = useState<SearchType>(SearchType.TITLE);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [visibleColumns, setVisibleColumns] = useState(
    columns.map((col) => col.key),
  );

  const handleTableChange = (
    _pagination: any,
    _filters: any,
    _sorter: any,
    extra: any,
  ) => {
    setCurrentDataSource(extra.currentDataSource);
  };

  const handleColumnToggle = (key: string) => {
    setVisibleColumns((prev) =>
      prev.includes(key) ? prev.filter((col) => col !== key) : [...prev, key],
    );
  };

  const filteredColumns = columns.filter((col) =>
    visibleColumns.includes(col.key),
  );

  const allKeywords = useMemo(() => {
    const allKeywords = new Set<string>();
    data?.forEach((artwork) => {
      artwork.keywords?.forEach((kw) => {
        allKeywords.add(kw);
      });
    });
    return Array.from(allKeywords);
  }, [data]);

  useEffect(() => {
    if (data && currentDataSource.length === 0) {
      setCurrentDataSource(data);
    }
  }, [data, currentDataSource]);

  useEffect(() => {
    setSearch('');
    setSearchSecondary('');
    setSelectedKeywords([]);
    if (searchType === SearchType.PRICE_RANGE) {
      setSearch('0');
      setSearchSecondary(DEFAULT_PRICE_RANGE_UPPER_BOUND.toString());
    }
    if (searchType === SearchType.AVAILABILITY) {
      setSearch(Availability.AVAILABLE);
    }
  }, [searchType]);

  const dataProcessed = useMemo(
    () =>
      filterData(
        data || [],
        searchType,
        search,
        searchSecondary,
        selectedKeywords,
      ),
    [data, searchType, search, searchSecondary, selectedKeywords],
  );

  const onClose = () => {
    setIsEditModalOpen(false);
    setSelectedItemId(undefined);
  };

  const handleClickExport = () => {
    setIsExportModalOpen(true);
  };

  const handleExport = async (fileTitle: string) => {
    try {
      setIsExporting(true);
      await exportData(filteredColumns, currentDataSource, fileTitle);
    } catch (e) {
      message.error('Une erreur est survenue');
    } finally {
      setIsExportModalOpen(false);
      setIsExporting(false);
    }
  };

  if (error) {
    return <div>Une erreur est survenue</div>;
  }

  return (
    <div>
      <h1 style={{ marginRight: '24px' }}>
        Inventaire{' '}
        {data && <span>{`(${dataProcessed?.length} résultats)`}</span>}
      </h1>

      <Flex
        style={{
          alignItems: 'center',
          marginBottom: '24px',
          marginRight: '12px',
        }}
      >
        <Flex>
          <Button
            type="primary"
            onClick={() => setIsEditModalOpen(true)}
            style={{ backgroundColor: 'black', lineHeight: '21px' }}
          >
            <PlusOutlined />
            <span style={{ fontWeight: 500, lineHeight: '21px' }}>
              Ajouter une oeuvre
            </span>
          </Button>
          <Button
            type="primary"
            onClick={handleClickExport}
            style={{ marginLeft: '12px', backgroundColor: 'black' }}
          >
            <ExportOutlined />
            <span style={{ fontWeight: 500, lineHeight: '21px' }}>
              Exporter
            </span>
          </Button>
          <div style={{ marginLeft: '12px' }}>
            <Dropdown
              menu={{
                items: columns.map((col) => ({
                  key: col.key,
                  label: (
                    <Checkbox
                      checked={visibleColumns.includes(col.key)}
                      onChange={() => handleColumnToggle(col.key)}
                      onClick={(e) => e.stopPropagation()}
                    >
                      {col.title}
                    </Checkbox>
                  ),
                })),
              }}
              trigger={['click']}
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: 'black',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <DashOutlined />
                <span style={{ fontWeight: 500 }}>Champs</span>
              </Button>
            </Dropdown>
          </div>
        </Flex>

        <Flex style={{ width: '500px', marginLeft: 'auto' }}>
          <SearchModeSelector
            searchType={searchType}
            onSearchTypeChanged={setSearchType}
          />

          {(searchType === SearchType.REFERENCE ||
            searchType === SearchType.TITLE) && (
            <Input
              allowClear
              style={{ marginLeft: '12px' }}
              width={'10%'}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
            />
          )}

          {searchType === SearchType.KEYWORD && (
            <div style={{ marginLeft: '12px', width: '100%' }}>
              <KeywordSelector
                keywordList={allKeywords}
                onSelectedKeywords={(keywords) => setSelectedKeywords(keywords)}
              />
            </div>
          )}

          {searchType === SearchType.PRICE_RANGE && (
            <Slider
              range
              defaultValue={[5000, 10000]}
              style={{ width: '100%', marginLeft: '24px' }}
              value={[Number(search), Number(searchSecondary)]}
              min={0}
              max={20000}
              step={100}
              tooltip={{
                formatter: (val) => `${val}€`,
              }}
              onChange={(values) => {
                setSearch(values[0].toString());
                setSearchSecondary(values[1].toString());
              }}
            />
          )}

          {searchType === SearchType.AVAILABILITY && (
            <div style={{ marginLeft: '12px', width: '100%' }}>
              <Radio.Group
                style={{ width: '100%' }}
                value={search}
                onChange={(value) => setSearch(value.target.value.toString())}
              >
                <Radio.Button value={Availability.AVAILABLE}>
                  Disponible
                </Radio.Button>
                <Radio.Button value={Availability.SOLD}>Vendu</Radio.Button>
              </Radio.Group>
            </div>
          )}

          {searchType === SearchType.LOCATION && (
            <div style={{ marginLeft: '12px', width: '100%' }}>
              <LocationDropdown
                currentLocationId={search}
                onChangeLocation={(newLocation) => {
                  if (newLocation) {
                    setSearch(newLocation.id);
                  }
                }}
              />
            </div>
          )}

          {searchType === SearchType.PROJECT && (
            <div style={{ marginLeft: '12px', width: '100%' }}>
              <ProjectDropdown
                currentProjectId={search}
                onChangeProject={(newProject) => {
                  if (newProject) {
                    setSearch(newProject.id);
                  }
                }}
              />
            </div>
          )}
        </Flex>
      </Flex>
      <Table
        loading={isLoading}
        onRow={(record) => {
          return {
            onClick: () => {
              setIsEditModalOpen(true);
              setSelectedItemId(record.id);
            },
          };
        }}
        style={{ overflow: 'auto', height: '800px' }}
        dataSource={dataProcessed}
        columns={filteredColumns}
        pagination={false}
        rowKey={(row: any) => row.id}
        onChange={handleTableChange}
      />
      {isEditModalOpen && (
        <AddOrEditItemModal onClose={onClose} itemId={selectedItemId} />
      )}

      {isExportModalOpen && (
        <ExportModal
          loading={isExporting}
          onConfirm={handleExport}
          onCancel={() => setIsExportModalOpen(false)}
        />
      )}
    </div>
  );
};

export default InventoryList;
