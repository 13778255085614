import { TabsProps, Tabs } from "antd";
import InventoryList from "./lists/InventoryList";
import LocationList from "./lists/LocationList";
import ProjectList from "./lists/ProjectList";

const tabs: TabsProps['items'] = [
  {
    key: 'inventory',
    label: 'Inventaire',
    children: <InventoryList />,
  },
  {
    key: 'projects',
    label: 'Projets',
    children: <ProjectList />,
  },
  {
    key: 'locations',
    label: 'Lieux',
    children: <LocationList />,
  },
];

const TabsComponent = () => {
  return (
    <Tabs defaultActiveKey="1" style={{ marginBottom: 32 }} items={tabs} />
  );
};

export default TabsComponent;
