import axios from 'axios';
import Cookies from 'js-cookie';
import { apiClient, configure } from './api';
import { useSetAtom } from 'jotai';
import { isLoggedInAtom } from '../atoms';

const ACCESS_TOKEN_COOKIE = 'access_token';

export interface LoginUser {
  email: string;
  password: string;
}

export enum LoginResponse {
  AUTHORIZED,
  UNAUTHORIZED,
  ERROR,
}

const performLogin = async (user: LoginUser) => {
  const response = await apiClient.post('login', user);
  return response.data;
};

interface UseAuthHook {
  login: (user: LoginUser) => Promise<LoginResponse>;
  logout: () => void;
}

export const useAuth = (): UseAuthHook => {
  const setIsLoggedIn = useSetAtom(isLoggedInAtom);

  const login = async (user: LoginUser): Promise<LoginResponse> => {
    try {
      const response = await performLogin(user);

      if (!response.access_token) {
        return LoginResponse.UNAUTHORIZED;
      }
      Cookies.set(ACCESS_TOKEN_COOKIE, response.access_token, { expires: 7 });
      setIsLoggedIn(true);

      return LoginResponse.AUTHORIZED;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const { response } = error;

        if (response) {
          switch (response.status) {
            case 401:
              return LoginResponse.UNAUTHORIZED;
            case 500:
              return LoginResponse.ERROR;
            default:
              return LoginResponse.ERROR;
          }
        }
      }
      return LoginResponse.ERROR;
    }
  };

  const logout = () => {
    Cookies.remove(ACCESS_TOKEN_COOKIE);
    setIsLoggedIn(true);
  };

  return { login, logout };
};
