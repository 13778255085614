import { Button, Flex, Form, Image, Input, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useState } from 'react';
import { LoginResponse, useAuth } from '../api/auth.api';
import { Navigate, useNavigate } from 'react-router-dom';

import logo from '../images/logo-cropped.jpg';
import { useAtom } from 'jotai';
import { isLoggedInAtom } from '../atoms';

const LoginPage = () => {
  const [isLoggedIn] = useAtom(isLoggedInAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSignIn = async (email: string, password: string) => {
    setIsLoading(true);
    const loginResponse = await login({ email, password });
    setIsLoading(false);

    if (loginResponse === LoginResponse.UNAUTHORIZED) {
      message.error('Le mot de passe est incorrect');
    } else if (loginResponse === LoginResponse.ERROR) {
      message.error('Une erreur est survenue');
    } else {
      navigate('/');
    }
  };

  const handleOnFinish = (values: any) => {
    handleSignIn(values.email, values.password);
  };

  if (isLoggedIn) {
    return <Navigate to={'/'} />;
  }
  
  return (
    <div style={{ height: '100%' }}>
      <Content>
        <Flex
          vertical
          style={{
            height: 'calc(100vh - 64px)',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image
            width={72}
            src={logo}
            style={{ marginBottom: '24px' }}
            preview={false}
          />
          <Form onFinish={handleOnFinish}>
            <Flex vertical style={{ width: '250px' }}>
              <Form.Item
                name="email"
                validateTrigger={[]}
                rules={[
                  { required: true, message: 'Veuillez renseigner un email' },
                  { type: 'email', message: 'Cet email est invalide' },
                ]}
              >
                <Input name="email" placeholder="Email" />
              </Form.Item>
              <Form.Item
                name="password"
                validateTrigger={[]}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez renseigner un mot de passe',
                  },
                ]}
              >
                <Input
                  name="password"
                  type="password"
                  placeholder="Mot de passe"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: '100%', backgroundColor: 'black' }}
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  disabled={isLoading}
                >
                  Se connecter
                </Button>
              </Form.Item>
            </Flex>
          </Form>
        </Flex>
      </Content>
    </div>
  );
};

export default LoginPage;
