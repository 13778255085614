import { mutate } from 'swr';
import { Project } from '../constants';
import { apiClient } from './api';

const createProject = async (project: Partial<Project>) => {
  const response = await apiClient.post('projects', project);
  return response.data;
};

const deleteProject = async (projectId: string) => {
  const response = await apiClient.delete(`projects/${projectId}`);
  return response.data;
};

export const handleCreateProject = async (project: Partial<Project>) => {
  try {
    await createProject(project);
    mutate('/projects');
  } catch (error) {
    console.log(error);
  }
};

export const handleDeleteProject = async (projectId: string) => {
  try {
    await deleteProject(projectId);
    mutate('/projects');
  } catch (error) {
    throw error;
  }
};
