import { format } from 'date-fns';
import {
  Artwork,
  formatSupport,
  formatTechnique,
  getServerImageUrl,
  Support,
  Technique,
} from './../constants';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const preProcessData = (data: Artwork[]) => {
  const dataCopy = [...data];
  for (let i = 0; i < data.length; i++) {
    if (data[i].support) {
      data[i].support = formatSupport(data[i].support as Support);
    }
    if (data[i].technique) {
      data[i].technique = formatTechnique(data[i].technique as Technique);
    }

    if (data[i].price) {
      data[i].price = (data[i].price || 0) / 100;
    }
    if (data[i].priceWithoutTax) {
      data[i].priceWithoutTax = (data[i].priceWithoutTax || 0) / 100;
    }
  }
  return dataCopy;
};

const loadImageAsBase64 = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const exportData = async (
  columns: any[],
  data: Artwork[],
  fileTitle: string,
) => {
  const processedData = preProcessData(data);
  const doc = new jsPDF();

  // Prepare table columns and rows
  const tableColumn = columns.map((col) => col.title);
  const tableRows = processedData.map((row: any) =>
    columns.map((col) => {
      // Replace the value in the 'imageUrl' column with an empty string
      if (col.dataIndex === 'imageId') {
        return ''; // No text in the cell where the image is drawn
      }
      return row[col.dataIndex];
    }),
  );

  // Map images to their corresponding rows
  const imageColumnIndex = columns.findIndex(
    (col) => col.dataIndex === 'imageId',
  );
  const imageRows = await Promise.all(
    processedData.map(async (row) =>
      row.imageId
        ? await loadImageAsBase64(getServerImageUrl(row.imageId))
        : null,
    ),
  );

  doc.text(`Export du ${format(new Date(), 'dd/MM/yyyy')}`, 14, 10);

  (doc as any).autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 20,
    didDrawCell: (data: any) => {
      if (data.column.index === imageColumnIndex && imageRows[data.row.index]) {
        const base64Image = imageRows[data.row.index];
        const cellPos = data.cell;
        const padding = 3; // Optional padding inside the cell
        doc.addImage(
          base64Image!,
          'JPEG',
          cellPos.x + padding,
          cellPos.y + padding,
          12,
          12,
        );
      }
    },
  });

  doc.save(fileTitle);
};
